import { useState } from "react"
import { PhoneInput } from "react-international-phone"
import { useNavigate, useSearchParams } from "react-router"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import { SubmitButton } from "~/themes/original/components/Button"
import InputField, { Label } from "~/themes/original/components/InputField"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes, { getHomeRoute } from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

import Layout, {
	Title,
	InputFieldRow,
	InputFields,
	NavigationLinks,
	NavigationLink,
	NavigationLinkButton,
} from "../Layout"
import PhoneNumberInputStyles from "./PhoneNumberInputStyles"
import { TermsOfService, TermsOfServiceButton } from "./styles"

const InvitedSignup: React.FC<{ inviteToken: string }> = ({ inviteToken }) => {
	const platform = usePlatform()
	const user = useUser()
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const alert = useAlert()

	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")
	const [password, setPassword] = useState("")
	const [passwordConfirmation, setPasswordConfirmation] = useState("")

	const [isSubmitting, setSubmitting] = useState(false)

	const { data: invite, error } = swr.user.verifyStudentInviteToken.useSWR(inviteToken)

	const handleSignup = async () => {
		if (
			invite?.invite == null ||
			firstName.trim().length === 0 ||
			lastName.trim().length === 0 ||
			password.length === 0 ||
			(platform.platform!.isPhoneNumberEnabled && phoneNumber.length === 0)
		)
			return

		if (password !== passwordConfirmation) return

		setSubmitting(true)

		try {
			const response = await trpc.user.regiterInvitedStudent.mutate({
				inviteToken,
				firstName: firstName.trim(),
				lastName: lastName.trim(),
				phoneNumber: platform.platform!.isPhoneNumberEnabled ? phoneNumber : null,
				password,
				platformId: platform.platform!.id,
			})

			if (response.status !== "success") {
				switch (response.status) {
					case "email-taken":
						await alert.show("Error", "That email address is already taken.")
						break
					case "expired-invite-token":
						await alert.show("Error", "That invite link has expired.")
						break
					case "claimed-invite-token":
						await alert.show("Error", "That invite link has already been claimed.")
						break
					case "invalid-invite-token":
						await alert.show("Error", "That invite link is invalid.")
						break
				}
				return
			}

			await user.reload()

			if (invite.invite.membership !== null) {
				navigate(`${routes.user.invitedSignupMembership()}?invite=${inviteToken}`)
			} else {
				navigate(getHomeRoute(platform.platform!))
			}
		} catch {
			await alert.show("Error", "Unknown error. Please try again later.")
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout onSubmit={handleSignup}>
			<Title>Sign up</Title>

			{error ? (
				<p>Failed to check link. Please try again later.</p>
			) : invite === undefined ? (
				<PartialLoadingPage />
			) : invite.status === "invalid" ? (
				<p>That invite link is invalid.</p>
			) : invite.status === "expired" ? (
				<p>That invite link has expired.</p>
			) : invite.invite.isClaimed ? (
				<p>That invite link has already been claimed.</p>
			) : (
				<>
					<InputFields>
						<InputField inputType="email" label="Email address" value={invite.invite.email} disabled />

						<InputFieldRow>
							<InputField
								inputType="text"
								label="First name"
								value={firstName}
								onChange={setFirstName}
								autoComplete="given-name"
							/>

							<InputField
								inputType="text"
								label="Last name"
								value={lastName}
								onChange={setLastName}
								autoComplete="family-name"
							/>
						</InputFieldRow>

						{platform.platform!.isPhoneNumberEnabled && (
							<div>
								<PhoneNumberInputStyles />
								<Label>Phone number</Label>
								<PhoneInput value={phoneNumber} onChange={setPhoneNumber} />
							</div>
						)}

						<InputFieldRow>
							<InputField inputType="password" label="Password" value={password} onChange={setPassword} />

							<InputField
								inputType="password"
								label="Confirm password"
								value={passwordConfirmation}
								onChange={setPasswordConfirmation}
							/>
						</InputFieldRow>
					</InputFields>

					<TermsOfService>
						By signing up you agree to our{" "}
						<TermsOfServiceButton onClick={routes.policy.termsOfService()} newTab>
							Terms of Service
						</TermsOfServiceButton>{" "}
						and{" "}
						<TermsOfServiceButton onClick={routes.policy.privacy()} newTab>
							Privacy Policy
						</TermsOfServiceButton>
						.
					</TermsOfService>

					<SubmitButton variant="primary" isLoading={isSubmitting}>
						Sign up
					</SubmitButton>

					<NavigationLinks>
						<NavigationLink>
							Already have an account?
							<NavigationLinkButton onClick={`${routes.user.signin()}?${searchParams}`}>
								Sign in
							</NavigationLinkButton>
						</NavigationLink>
					</NavigationLinks>
				</>
			)}
		</Layout>
	)
}

export default InvitedSignup
