import { type ComponentType } from "react"

import { hasAccessToViewCommunity } from "@forento/shared/utilities/community"

import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import {
	BundleIcon,
	CalendarIcon,
	CertificateIcon,
	CommunityIcon,
	DownloadIcon,
	MembershipIcon,
	MessageIcon,
	QuizIcon,
	SessionsIcon,
	UsersIcon,
	VideoIcon,
} from "~/themes/school/components/Icon"
import { CourseIcon } from "~/themes/school/components/Icon"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"

type NavigationItem = { label: string; onClick: string; icon: ComponentType }
export default function useNavigationItems(): NavigationItem[] {
	const platform = usePlatform().platform
	const user = useUser().user
	const t = useTranslation()
	const items: NavigationItem[] = []

	if (platform.content.courses) {
		items.push({ label: t("course.title"), onClick: routes.course.index(), icon: CourseIcon })
	}
	if (platform.content.exams) {
		items.push({ label: t("exams"), onClick: routes.exam.index(), icon: QuizIcon })
	}
	if (platform.content.videos) {
		items.push({ label: t("video.title"), onClick: routes.video.index(), icon: VideoIcon })
	}
	if (platform.content.downloadables) {
		items.push({ label: t("downloadable.title"), onClick: routes.downloadable.index(), icon: DownloadIcon })
	}
	if (platform.content.bundles) {
		items.push({ label: "Bundles", onClick: routes.bundle.index(), icon: BundleIcon })
	}
	if (platform.content.sessions) {
		items.push({ label: t("sessions"), onClick: routes.session.index(), icon: SessionsIcon })
	}
	if (platform.content.events) {
		items.push({ label: t("event.title"), onClick: routes.event.index(), icon: CalendarIcon })
	}
	if (platform.content.certificates) {
		items.push({ label: t("certificate.title"), onClick: routes.certificate.index(), icon: CertificateIcon })
	}
	if (user !== null && platform.community !== null && hasAccessToViewCommunity(user, platform.community)) {
		items.push({ label: t("community.title"), onClick: routes.community.index(), icon: CommunityIcon })
		items.push({
			label: t("community.leaderboard.title"),
			onClick: routes.community.leaderboard(),
			icon: UsersIcon,
		})
		items.push({ label: t("messages"), onClick: routes.community.conversations(), icon: MessageIcon })
	}
	if (platform.content.memberships) {
		items.push({ label: t("membership.title"), onClick: routes.membership.index(), icon: MembershipIcon })
	}

	return items
}
