import { type ComponentType, type FC, useEffect, useMemo, useState } from "react"
import { createPortal } from "react-dom"
import styled, { css, keyframes } from "styled-components"

import Button from "~/themes/school/components/Button"
import { MoreOptionsIcon } from "~/themes/school/components/Icon"
import { dangerColor } from "~/utilities/styles"

const menuWidth = 200

type State = "idle" | "open" | "closed"

interface Props {
	actions: {
		label: string
		icon: ComponentType
		onClick(): void
		isDanger?: boolean
	}[]
}

const MoreActions: FC<Props> = ({ actions }) => {
	const [state, setState] = useState<State>("idle")
	const [buttonElement, setButtonElement] = useState<(HTMLDivElement & HTMLButtonElement & HTMLAnchorElement) | null>(
		null,
	)

	const position = useMemo(() => {
		if (buttonElement === null || state === "idle") return { x: 0, y: 0 }
		const bounds = buttonElement.getBoundingClientRect()
		return {
			x: bounds.x - menuWidth + bounds.width,
			y: window.scrollY + bounds.y + bounds.height + 16,
		}
	}, [buttonElement, state])

	useEffect(() => {
		if (state !== "open") return

		function handleClick(event: MouseEvent) {
			if (event.target instanceof Node && buttonElement?.contains(event.target)) return
			setState("closed")
		}

		document.addEventListener("click", handleClick)

		return () => {
			document.removeEventListener("click", handleClick)
		}
	}, [buttonElement, state])

	return (
		<>
			<StyledButton
				ref={setButtonElement}
				onClick={() => setState(current => (current !== "open" ? "open" : "closed"))}
			>
				<MoreOptionsIcon />
			</StyledButton>
			{state !== "idle" &&
				createPortal(
					<Menu $state={state} $x={position.x} $y={position.y}>
						{actions.map(action => (
							<Item key={action.label} onClick={action.onClick} isDanger={action.isDanger}>
								<ItemIcon as={action.icon} />
								{action.label}
							</Item>
						))}
					</Menu>,
					document.body,
				)}
		</>
	)
}

const openAnimation = keyframes`
	from { transform: scale(0); }
	to { transform: scale(1); }
`

const closeAnimation = keyframes`
	from { transform: scale(1); }
	to { transform: scale(0); }
`

const StyledButton = styled(Button)`
	grid-area: actions;
	width: 24px;
	height: 24px;
`

const Menu = styled.div<{ $state: State; $x: number; $y: number }>`
	position: absolute;
	width: ${menuWidth}px;
	top: ${props => props.$y}px;
	left: ${props => props.$x}px;
	transform: scale(0);
	transform-origin: top right;
	box-shadow: 0 20px 40px -2px rgba(0, 0, 0, 0.16);
	border-radius: 8px;
	overflow: hidden;

	${props =>
		props.$state === "open"
			? css`
					animation: ${openAnimation} 0.15s forwards;
				`
			: props.$state === "closed"
				? css`
						animation: ${closeAnimation} 0.15s forwards;
					`
				: ""}
`

const Item = styled(Button)<{ isDanger?: boolean }>`
	width: 100%;
	padding: 8px 26px;
	background-color: white;
	transition: 0.1s background-color;
	font-size: 14px;
	display: flex;
	gap: 8px;
	align-items: center;

	${props =>
		props.isDanger &&
		css`
			color: ${dangerColor};
		`}

	&:hover {
		background-color: #f2f2f2;
	}

	&:active {
		background-color: #e6e6e6;
	}
`

const ItemIcon = styled.div`
	width: 14px;
	height: 14px;
`

export default MoreActions
