import { type FC, useEffect } from "react"
import { Navigate, useNavigate, useSearchParams } from "react-router"
import styled from "styled-components"

import { useUser } from "~/contexts/UserContext"
import Layout from "~/themes/school/components/Layout"
import LoadingIndicator from "~/themes/school/components/LoadingIndicator"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

const CompletingPayPalPage: FC = () => {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const user = useUser()
	const t = useTranslation()

	const payPalSubscriptionId = searchParams.get("subscription_id")

	useEffect(() => {
		const abortController = new AbortController()

		async function check() {
			if (payPalSubscriptionId === null || payPalSubscriptionId.trim().length === 0) return
			const response = await trpc.payment.paypal.getSubscriptionByPayPalSubscriptionId.query(
				payPalSubscriptionId,
				{ signal: abortController.signal },
			)
			if (abortController.signal.aborted) return
			if (!response.isActive) {
				return setTimeout(() => {
					check()
				}, 1000)
			}

			await user.reload()
			navigate(routes.membership.index())
		}

		check()

		return () => {
			abortController.abort()
		}
	}, [navigate, payPalSubscriptionId, user])

	if (payPalSubscriptionId === null) return <Navigate to={routes.membership.index()} />

	return (
		<Layout>
			<Container>
				<LoadingIndicator />
				<p>{t("membership.starting")}</p>
			</Container>
		</Layout>
	)
}

const Container = styled.div`
	background-color: white;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
`

export default CompletingPayPalPage
