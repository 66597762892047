import { createContext, type FC, type ReactNode, useContext, useEffect, useState } from "react"
import { useSearchParams } from "react-router"

import { useThemeComponents } from "~/contexts/ThemeComponentContext"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

interface Value {
	show(): void
}

const Context = createContext<Value>({
	show() {
		throw new Error("PurchaseSuccessModalContext is not in tree!")
	},
})

export const usePurchaseSuccessModal = () => useContext(Context)

interface ProviderProps {
	content: { isUnlocked: boolean; payPalOrderId: number | null } & (
		| { type: "course"; id: number }
		| { type: "video" | "downloadable" | "bundle" | "session" | "event" }
	)
	children: ReactNode
}

const parameterKey = "purchase-successful"

export const PurchaseSuccessModalContextProvider: FC<ProviderProps> = ({ content, children }) => {
	const { Button, Modal } = useThemeComponents()
	const [searchParams, setSearchParams] = useSearchParams()

	const isPurchaseSuccessfulParam = searchParams.get(parameterKey) === "1"

	const [isOpen, setOpen] = useState(false)

	const payPalOrder = swr.payment.paypal.getOrderById.useSWR(content.payPalOrderId ?? -1, {
		isDisabled: content.payPalOrderId === null,
	})

	useEffect(() => {
		if (isPurchaseSuccessfulParam && content.isUnlocked) {
			setOpen(true)
			setSearchParams(current => {
				current.delete(parameterKey)
				return current
			})
		}
	}, [isPurchaseSuccessfulParam, content.isUnlocked, setSearchParams])

	const buttons: ReactNode = (() => {
		switch (content.type) {
			case "course":
				return (
					<>
						<Button variant="secondary" onClick={() => setOpen(false)}>
							Close
						</Button>
						<Button variant="primary" onClick={routes.course.player(content.id)}>
							Start course
						</Button>
					</>
				)
			case "video":
				return (
					<Button variant="primary" onClick={() => setOpen(false)}>
						Close
					</Button>
				)
			case "downloadable":
				return (
					<Button variant="primary" onClick={() => setOpen(false)}>
						Close
					</Button>
				)
			case "bundle":
				return (
					<Button variant="primary" onClick={() => setOpen(false)}>
						Close
					</Button>
				)
			case "session":
				return (
					<Button variant="primary" onClick={() => setOpen(false)}>
						Close
					</Button>
				)
			case "event":
				return (
					<Button variant="primary" onClick={() => setOpen(false)}>
						Close
					</Button>
				)
		}
	})()

	return (
		<Context.Provider value={{ show: () => setOpen(true) }}>
			<Modal.Frame isOpen={isOpen}>
				<Modal.Title>Purchase successful</Modal.Title>
				<Modal.Text>You have successfully purchased this {content.type}!</Modal.Text>
				{content.payPalOrderId !== null &&
					(payPalOrder.error ? (
						<Modal.Text>Failed to load PayPal order info.</Modal.Text>
					) : payPalOrder.data === undefined ? (
						<Modal.Text>Loading PayPal order info...</Modal.Text>
					) : payPalOrder.data !== null ? (
						<Modal.Text>
							PayPal customer email:
							<br />
							{payPalOrder.data.customerEmail}
						</Modal.Text>
					) : null)}
				<Modal.Buttons>{buttons}</Modal.Buttons>
			</Modal.Frame>
			{children}
		</Context.Provider>
	)
}
