import { type FC } from "react"

import { toPriceString } from "@forento/shared/utilities/currency"

import Card, { Cards } from "~/themes/original/components/Card"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import { routes } from "~/themes/original/pages"
import { swr } from "~/utilities/trpc"

const BundlesPage: FC = () => {
	const { data: bundles, error } = swr.bundle.listPersonalized.useSWR()

	return (
		<Layout>
			{error ? (
				<p>Failed to load bundles.</p>
			) : bundles === undefined ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{bundles.map(bundle => (
						<Card
							key={bundle.id}
							title={bundle.name}
							description={bundle.shortDescription ?? undefined}
							thumbnailFilePath={bundle.thumbnailFilePath}
							sticker={[
								{ text: toPriceString(bundle.price), color: "orange" },
								{
									text: `${bundle.contentPrice > 0 ? Math.floor((1 - bundle.price.amount / bundle.contentPrice) * 100).toFixed(0) : 0}% off`,
									color: "green",
								},
							]}
							onClick={routes.bundle.detail(bundle.id)}
						/>
					))}
				</Cards>
			)}
		</Layout>
	)
}

export default BundlesPage
