import { type ReactNode, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import styled, { css } from "styled-components"

import Button from "~/themes/school/components/Button"
import { fonts } from "~/themes/school/styles"

interface TabsProps {
	tabs: {
		title: string
		content: ReactNode
		icon?: ReactNode
	}[]
	selectedTabIndex: number
	setSelectedTabIndex: (value: number) => void
	className?: string
}

interface ControlledTabsProps {
	tabs: {
		title: string
		content: ReactNode
	}[]
	className?: string
}

interface RouteTabsProps {
	tabs: {
		title: string
		route: string
		content: ReactNode
	}[]
	className?: string
}

const Tabs: React.FC<TabsProps> = ({ selectedTabIndex, setSelectedTabIndex, tabs, className }) => (
	<div className={className}>
		<TabBar>
			{tabs.map(({ title }, index) => (
				<Tab key={index} isActive={selectedTabIndex === index} onClick={() => setSelectedTabIndex(index)}>
					{title}
				</Tab>
			))}
		</TabBar>
		{tabs.map(({ content }, index) => (
			<TabContent key={index} $isActive={selectedTabIndex === index}>
				{content}
			</TabContent>
		))}
	</div>
)

export const ControlledTabs: React.FC<ControlledTabsProps> = props => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0)

	return <Tabs selectedTabIndex={selectedTabIndex} setSelectedTabIndex={setSelectedTabIndex} {...props} />
}

export const RouteTabs: React.FC<RouteTabsProps> = ({ tabs, ...props }) => {
	const location = useLocation()
	const navigate = useNavigate()

	const routeIndex = tabs.findIndex(x => x.route === location.pathname)

	return (
		<Tabs
			tabs={tabs}
			selectedTabIndex={routeIndex >= 0 ? routeIndex : 0}
			setSelectedTabIndex={index => navigate(tabs[index].route)}
			{...props}
		/>
	)
}

const TabBar = styled.div`
	width: 100%;
	display: flex;
	gap: 22px;
	overflow-x: auto;
`

const Tab = styled(Button)<{ isActive: boolean }>`
	font-family: ${fonts.quicksand};
	font-size: 14px;
	font-weight: bold;
	color: ${props => (props.isActive ? props.theme.primaryColor : "#454D66")};
	white-space: nowrap;
`

const TabContent = styled.div<{ $isActive: boolean }>`
	padding-top: 16px;

	${props =>
		!props.$isActive &&
		css`
			display: none;
		`}
`

export default Tabs
