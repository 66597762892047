import { type FC, useState } from "react"
import { useNavigate, useSearchParams } from "react-router"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import Button, { SubmitButton } from "~/themes/school/components/Button"
import InputField from "~/themes/school/components/InputField"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

import Layout, { Buttons, Divider, InputFields, Text } from "./Layout"

const SigninPage: FC = () => {
	const alert = useAlert()
	const platform = usePlatform().platform!
	const user = useUser()
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const t = useTranslation()
	const params = new URLSearchParams(window.location.search)

	const nextUrl = params.get("next") ?? undefined
	const passwordResetEmail = params.get("password-reset-email") ?? undefined
	const prefilledEmail = params.get("email") ?? undefined

	const [email, setEmail] = useState(passwordResetEmail ?? prefilledEmail ?? "")
	const [password, setPassword] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const handleSignin = async () => {
		if (email.trim().length === 0 || password.length === 0) return

		setSubmitting(true)

		try {
			const result = await trpc.user.loginPassword.mutate({
				email: email.trim(),
				password,
				platformId: platform.id,
			})
			if (result.status !== "success") {
				await alert.show(t("user.signIn.failed"), t("user.signIn.failedMessage"))
				return
			}
			await user.reload()

			navigate(nextUrl ?? "/")
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout title={t("user.signIn.title")} onSubmit={handleSignin}>
			{passwordResetEmail !== undefined && <Text>{t("user.resetPassword.completed")}</Text>}

			<InputFields>
				<InputField
					inputType="email"
					label={t("user.general.email")}
					value={email}
					onChange={setEmail}
					disabled={prefilledEmail !== undefined || passwordResetEmail !== undefined}
				/>

				<InputField
					inputType="password"
					label={t("user.general.password")}
					value={password}
					onChange={setPassword}
				/>
			</InputFields>

			<Buttons>
				<SubmitButton variant="primary" isLoading={isSubmitting}>
					{t("user.signIn.title")}
				</SubmitButton>

				<Divider />

				<Button variant="secondary" onClick={routes.user.resetPassword()}>
					{t("user.resetPassword.title")}
				</Button>

				<Button variant="secondary" onClick={`${routes.user.signup()}?${searchParams}`}>
					{t("user.signUp.title")}
				</Button>
			</Buttons>
		</Layout>
	)
}

export default SigninPage
