import { type FC } from "react"
import { Link } from "react-router"
import styled from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { toPriceString } from "@forento/shared/utilities/currency"

import Card, { CardHeader, Cards, CardSticker, CardText, CardTitle } from "~/themes/school/components/Card"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { backgroundColor } from "~/themes/school/styles"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

const BundlesPage: FC = () => {
	const t = useTranslation()

	const { data, error } = swr.bundle.listPersonalized.useSWR()

	return (
		<Layout>
			<PageTitle>{t("bundle.title")}</PageTitle>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : !data ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{data.map(card => (
						<Card key={card.id} as={Link} to={routes.bundle.detail(card.id)}>
							<CardHeader>
								<CardTitle>{card.name}</CardTitle>
								<CardSticker>
									{t("bundle.discount", {
										discount:
											card.contentPrice > 0
												? Math.floor((1 - card.price.amount / card.contentPrice) * 100)
												: 0,
									})}
								</CardSticker>
							</CardHeader>
							<AspectRatioContainer>
								<StyledAspectRatio aspectRatio={16 / 9} width={{ value: 100, unit: "percent" }}>
									<ThumbnailContainer>
										{card.thumbnailFilePath !== null && <Thumbnail src={card.thumbnailFilePath} />}
										<Price>{toPriceString(card.price)}</Price>
									</ThumbnailContainer>
								</StyledAspectRatio>
							</AspectRatioContainer>
							<CardText>{card.shortDescription}</CardText>
						</Card>
					))}
				</Cards>
			)}
		</Layout>
	)
}

const AspectRatioContainer = styled.div`
	margin: 0 42px;
`

const StyledAspectRatio = styled(AspectRatio)`
	background-color: ${backgroundColor};
	overflow: hidden;
`

const ThumbnailContainer = styled.div`
	position: relative;
`

const Thumbnail = styled.img.attrs({ alt: "Bundle thumbnail" })`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const Price = styled.p`
	position: absolute;
	top: 16px;
	right: 16px;
	font-weight: bold;
	font-size: 16px;
	color: #ffa412;
	background-color: white;
	padding: 4px 8px;
`

export default BundlesPage
