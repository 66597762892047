import { createPortal } from "react-dom"
import styled from "styled-components"

import { swr } from "~/utilities/trpc"

import Button from "../Button"
import PartialLoadingPage from "../PartialLoadingPage"
import Reaction from "./Reaction"

const PostReactions: React.FC<{ postId: number | null; onClose(): void }> = ({ postId, onClose }) => {
	const { data: reactions, error } = swr.community.listPostReactions.useSWR(postId!, { isDisabled: postId === null })

	if (postId === null) return null

	return createPortal(
		<Container>
			<Frame>
				<Title>Likes</Title>
				{reactions === undefined ? (
					<PartialLoadingPage />
				) : error ? (
					<p>Failed to load likes.</p>
				) : (
					<Reactions>
						{reactions.map(reaction => (
							<Reaction key={reaction.profile.id} reaction={reaction} />
						))}
					</Reactions>
				)}
				<StyledButton variant="primary" onClick={onClose}>
					Close
				</StyledButton>
			</Frame>
		</Container>,
		document.body,
	)
}

const Container = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
`

const Frame = styled.div`
	width: 100%;
	max-width: 400px;
	max-height: 80vh;
	padding: 16px;
	border-radius: 8px;
	background-color: white;
	box-shadow: 0 20px 40px -2px rgba(0, 0, 0, 0.16);
	display: flex;
	flex-direction: column;
	gap: 12px;
`

const Reactions = styled.div`
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow-y: scroll;
`

const Title = styled.h1`
	flex: 0 0 auto;
	font-weight: 600;
	font-size: 20px;
	color: #151d48;
`

const StyledButton = styled(Button)`
	flex: 0 0 auto;
`

export default PostReactions
