import { type ReactNode } from "react"
import { Link } from "react-router"
import styled from "styled-components"

import { usePlatform } from "~/contexts/PlatformContext"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

type Result =
	| { status: "error" | "loading" }
	| {
			status: "success"
			terms: {
				static: {
					introItems: ReactNode[]
					sections: { title: string; skipItemNumbers?: boolean; items: ReactNode[] }[]
				}
				custom: string | null
			}
	  }
export function useTermsOfService(): Result {
	const platform = usePlatform()

	const { error, data: customTerms } = swr.platform.getCustomTerms.useSWR()

	if (error) return { status: "error" }
	if (customTerms === undefined) return { status: "loading" }

	return {
		status: "success",
		terms: {
			static: {
				introItems: [
					<>
						The following terms (”Terms”) apply between a person or company who has accepted to be bound by
						them (”you”) and {platform.platform!.name} (the “Tutor”), who provides online courses and/or
						memberships (as offered from time to time, referred to as the “Services”) through its website,{" "}
						<InlineButton to={`https://${platform.platform!.domain.platform}`}>
							{platform.platform!.domain.platform}
						</InlineButton>{" "}
						(the “Website”). The Terms and your acceptance of them is hereinafter referred to as the
						“Agreement”, and you and the Tutor are hereinafter individually referred to as a “Party” and
						collectively as the “Parties”.
					</>,
					<>
						By accepting the Terms and/or purchasing a course or membership, you acknowledge that you have
						read and understood these Terms and agree to be bound by the terms and conditions set out below.
					</>,
				],
				sections: [
					{
						title: "DEFINITIONS",
						skipItemNumbers: true,
						items: [
							<>
								For the purpose of the Terms, the following expression shall have the following
								meanings:
							</>,
							<>
								"Know-how" shall mean knowledge, experience, data, technology, designs, techniques,
								drawings, software, and other information and knowledge, relating to the Services.
							</>,
							<>
								"Material" shall mean study material and all other material used in or otherwise related
								to the Services which is provided by the Tutor.
							</>,
							<>
								"Consumer" shall mean a person who purchases Services for its own, non-commercial, use
								according to the definition in the Swedish Act on Distance Contracts and Off-Premises
								Contracts (2005:59) (Swedish: distansavtalslagen).
							</>,
						],
					},
					{
						title: "PAYMENT",
						items: [
							<>
								The fee for the Services is from time to time published on the Website. Your payments
								for the Services shall be made by following the payment instructions set forth on the
								Website. Any payment will be made through a third-party's payment platform ("Third Party
								Financial Company", currently Stripe). You agree that the processing of payments shall
								be subject to the terms, conditions and privacy policies of the Third-Party Financial
								Company. The terms and conditions of the current Third-Party Financial Company (Stripe)
								can be accessed here:{" "}
								<InlineButton to="https://stripe.com/ssa">stripe.com/ssa</InlineButton>.
							</>,
							<>
								Any late payment shall accrue interest according to the Swedish Interest Act (1975:635).
							</>,
						],
					},
					{
						title: "CONSUMERS' RIGHT OF WITHDRAWAL",
						items: [
							<>
								In the event you are a Consumer, the provisions of this section 3 shall apply. All
								mandatory applicable laws and regulations shall apply to the Consumer (e.g. the Distance
								and Off-Premises Contracts Act (SFS 2005:59) and the Consumer Services Act (SFS
								1985:716)). In relation to any Services purchased by you, you shall have a period of
								fourteen (14) days, to withdraw from your purchase ("Withdrawal period" or in Swedish;
								"ångerrättsperiod"). The Withdrawal period shall expire after fourteen (14) days from
								your purchase.
							</>,
							<>
								However, if you start using the Services, you thereby accept that the right of
								withdrawal according to section above is waived. Notwithstanding this, you still have
								the right to terminate the Agreement in accordance with section 10 below. The right of
								withdrawal applies when the Consumer and the Tutor are entering into the Agreement in
								accordance with the Swedish Act on Distance Contracts and Off-Premises Contracts.
							</>,
							<>
								If the Consumer withdraws from his/her purchase, the Consumer shall inform the Tutor of
								its decision thereof by sending an email to{" "}
								<InlineButton to={`mailto:${platform.platform!.contactEmail}`}>
									{platform.platform!.contactEmail}
								</InlineButton>{" "}
								or by using the form available at the Swedish Consumer Agency's (Swedish:
								Konsumentverket) website (
								<InlineButton to="https://www.konsumentverket.se/">www.konsumentverket.se</InlineButton>
								; “Ångerblankett”).
							</>,
							<>
								If the Consumer withdraws from his/her purchase in accordance with this section, the
								Tutor shall, where applicable, reimburse the Consumer for any payments received from the
								Consumer. The Consumer is not entitled to any reimbursement if the Consumer has accessed
								the Services during the Withdrawal period.
							</>,
						],
					},
					{
						title: "USE OF THE SERVICES",
						items: [
							<>
								Any information provided by you shall be correct and legal and you shall not damage,
								interfere with or prevent access to the Services or otherwise harm the Services. You
								shall only use the Services in a prudent manner. Further, you shall not upload or
								otherwise provide any content in the Service that may reasonably be considered as
								racist, sexist or otherwise discriminatory, and you may only use the Services in
								accordance with law.
							</>,
							<>
								Any incorrect use may lead to being blocked from further use of the Services, without
								any reimbursement of payments already made to the Tutor. You agree and acknowledge that
								the Tutor maintains the right to delete any content submitted by you when using the
								Services, such as any comments submitted through comment fields.
							</>,
						],
					},
					{
						title: "UPDATES OF THE TERMS",
						items: [
							<>
								The Tutor retains the right to update, amend or otherwise modify the Terms at its own
								absolute discretion. Such updates, amendments, and modifications shall be published on
								the Website in due time prior to their entry into force and they will take effect on the
								date stated on the Website.
							</>,
							<>
								If the price of the Services is increased or in the event of a material update,
								amendment, or modification of the Terms, you shall be notified thereof (by email and/or
								a notice on the Website) no later than 30 days before the amended price/terms become(s)
								effective. If you do not accept the amended price/terms, you have the right to terminate
								the Agreement in accordance with Section 10 whereby the agreed price and terms shall
								apply during the remaining part of the contract period. Your continued use of the
								Services after having received a such notice as referred to above, shall be deemed as
								your acceptance of the amended price/terms.
							</>,
							<>
								For the avoidance of doubt, any such updates, amendments and modifications (including in
								relation to price increases), may apply in relation to a certain user only (including
								you).
							</>,
						],
					},
					{
						title: "WARRANTIES AND LIMITATION OF LIABILITY PROVISIONS",
						items: [
							<>
								The warranties and limitation of liability provisions of this section 6 apply to the
								fullest extent possible under applicable law (subject to section 18). More favourable
								terms may apply in relation to you if you are a Consumer (as set forth in the Swedish
								Consumer Sales Act (2022:260) (Swedish: konsumentköplagen) and/or the Swedish Consumer
								Services Act (1985:716) (Swedish: konsumenttjänstlagen)).
							</>,
							<>
								The Services are provided without warranty of merchantability or fitness for a
								particular purpose or any other warranty, expressed or implied. The Tutor does not
								warrant or guarantee that the Services will be provided without interruptions, that any
								non-conformity arises, nor that the Services are free from defects and other errors. The
								Services are provided on an "as-is" basis and the Tutor does not provide any express
								warranties including without limitation regarding the standard, delivery, timing, and
								security of the Services. The Tutor will use its best efforts to ensure the availability
								of the Services.
							</>,
							<>
								The Tutor reserves the right to modify, suspend, remove, and amend the Services,
								including the content of the Services, in its own discretion and without prior notice.
								Amendments that significantly alter your use of the Services will, however, be announced
								to the Customer at least 30 days before the change becomes effective whereupon the
								Agreement may be terminated in accordance with the principles of clause 5.2.
							</>,
							<>
								The Tutor is not responsible for services, or any other activities provided by its
								associated partners or any other third parties (including when the Services include
								links to websites provided by third parties). The Tutor shall not be held responsible
								for any measures taken by its associated partners or any other third parties, including
								but not limited to changes in ownership, insolvency and/or litigation.
							</>,
							<>
								NOTWITHSTANDING ANYTHING ELSE IN THESE TERMS, YOU SHALL NOT (AND SHALL NOT PERMIT ANY
								THIRD PARTY) TO USE THE SERVICES IN A MANNER THAT WOULD INFRINGE UPON THE INTELLECTUAL
								PROPERTY RIGHTS OF THE TUTOR OR ANY THIRD PARTY, OR FOR ANY ILLEGAL TRANSACTION OR
								UNLAWFUL PURPOSE.
							</>,
						],
					},
					{
						title: "LIMITATION OF LIABILITY",
						items: [
							<>
								Any claim for compensation by you must be made at least two (2) calendar months after
								the date when you first became aware of the circumstances which entitle to compensation.
								Notwithstanding anything said in this Agreement, the Tutor does not seek to limit
								liability in respect of mandatory laws and regulations in respect of Consumers or
								otherwise.
							</>,
							<>
								The Tutor's liability under these Terms shall at all times be limited to the amount
								equal to the total fees paid by you for Services during the twelve (12) calendar months
								prior to the claim. If twelve (12) calendar months have not passed since you purchased
								the Services, the limitation shall nevertheless be equivalent to twelve (12) calendar
								months of the total fees that would have been paid.
							</>,
							<>
								No Party shall be responsible to the other Party for indirect or consequential loss or
								similar damage such as, but not limited to, loss of profit, loss of revenue or loss of
								contracts.
							</>,
							<>
								Notwithstanding anything said in these Terms, neither Party excludes liability for gross
								negligence, intentional misconduct, fraudulent misrepresentation, or personal injury.
								Notwithstanding anything said in these Terms, the Tutor is not seeking to limit any
								right that you may have under mandatory laws and regulations.
							</>,
						],
					},
					{
						title: "DATA PROCESSING AND TRANSFER",
						skipItemNumbers: true,
						items: [
							<>
								Your personal data shall be treated in accordance with the Tutor's Privacy Policy,
								available at
								<InlineButton to={routes.policy.privacy()}>
									{platform.platform!.domain.platform}
									{routes.policy.privacy()}
								</InlineButton>{" "}
								and shall be subject to European Union Regulation (EU) No. 2016/679 of 27 April 2016,
								also known as the General Data Protection Regulation ("GDPR").
							</>,
						],
					},
					{
						title: "INTELLECTUAL PROPERTY RIGHTS",
						items: [
							<>
								By purchasing the Services, you are granted a non-commercial, non-exclusive, limited,
								personal, non-transferable, non-sublicensable, revocable licence to use the Services
								solely for their intended purpose. The Tutor retains the right to all trademarks,
								non-registered trademarks, all intellectual property rights, including copyright,
								patents, sui generis database rights and the right to all concepts and technologies held
								by the Tutor. You may not use the Tutor's trademarks and/or Know-how or other
								intellectual property rights held or licenced by the Tutor without the prior written
								consent from the Tutor. You may not at any time abuse the Tutor's trademarks and/or
								Know-how or other intellectual property rights held or licenced by the Tutor.
							</>,
							<>
								You are provided with the right to use the Material for the purpose of the Service and
								for your private purposes only. You do not have the right to copy, distribute or
								otherwise reproduce the Material or otherwise reproduce or develop the Service for other
								than personal use.
							</>,
						],
					},
					{
						title: "TERM AND TERMINATION",
						items: [
							<>
								The Terms shall enter into force on the date when you accept the Terms ("Effective
								Date") as set forth in the preamble of the Terms.
							</>,
							<>
								If you purchase an online course, you have the right to access such and complete it at
								your own pace however subject to any express limitation period that the Tutor may
								regulate. If the Tutor has regulated otherwise in writing on its website, then such
								terms shall apply and take priority of these Terms.
							</>,
							<>
								If you subscribe for a membership, current fee structure and the term of such membership
								is set forth on the Tutor's website:{" "}
								<InlineButton to={`https://${platform.platform!.domain.platform}`}>
									{platform.platform!.domain.platform}
								</InlineButton>
								.
							</>,
						],
					},
					{
						title: "PREMATURE TERMINATION DUE TO BREACH OF CONTRACT OR INSOLVENCY",
						items: [
							<>
								Without prejudice to any remedy, it may have against the other for breach or
								non-performance of the Agreement, either Party shall have the right to terminate the
								Agreement by giving the other Party not less than thirty (30) days notice in writing:
								<List>
									<li>
										if the other Party should commit or permit a breach or non-performance of
										essential importance to the other Party; or
									</li>
									<li>
										if the other Party should enter into liquidation, either voluntary or
										compulsory, or become insolvent or enter into composition or corporate
										reorganisation proceedings or if execution be levied on any goods and effects of
										the other Party or the other Party should enter into receivership.
									</li>
								</List>
							</>,
							<>
								Notice of termination shall be given without undue delay after the circumstance
								constituting the breach was or should have been known to the aggrieved Party.
							</>,
							<>
								In the event of your substantial breach of the Terms or if you otherwise misuse the
								Services, the Tutor shall also be entitled to suspend you from further use of the
								Services. In the event the Tutor suspends or terminates your use of the Services, you
								understand and agree that you shall not be entitled to any reimbursement of payments
								previously made to the Tutor.
							</>,
						],
					},
					{
						title: "CONFIDENTIALITY",
						skipItemNumbers: true,
						items: [
							<>
								All information contained in the Services shall be treated confidentially. You must
								treat all Material confidentially and undertake not to distribute the Material or share
								the Material with any third party (i.e., including a party which has not purchased the
								Services), without prior written consent from the Tutor.
							</>,
						],
					},
					{
						title: "ASSIGNMENT OF THE AGREEMENT",
						skipItemNumbers: true,
						items: [
							<>
								You may not wholly or partly assign or pledge your rights or obligations under the Terms
								to any third party without the prior written consent of the Tutor.
							</>,
						],
					},
					{
						title: "NOTICES AND LANGUAGE",
						skipItemNumbers: true,
						items: [
							<>
								Any notice, request, consent and other communication to be given by a Party under the
								Terms shall be in the English or Swedish language. Any notice to be given by either
								Party pursuant to these Terms must be in writing and may be sent by email to the e-mail
								address of the recipient Party or as otherwise stipulated by the Tutor.
							</>,
						],
					},
					{
						title: "AMENDMENTS",
						skipItemNumbers: true,
						items: [
							<>
								These Terms may be amended from time to time. Supplemental terms and conditions or
								documents are hereby expressly incorporated herein by reference. You waive any right to
								receive specific notice of each such amendment.
							</>,
						],
					},
					{
						title: "FORCE MAJEURE",
						items: [
							<>
								Either Party is relieved from any and all liabilities by reason of any circumstances
								beyond its immediate control, which impedes, delays or aggravates any obligation to be
								fulfilled by it under the Terms, such as changes in laws and regulations or in the
								interpretation thereof, acts of authorities, war, acts of war, labour disputes,
								blockades, major accidents, epidemic, pandemic (including any governmental decisions
								related to Covid-19) and currency restrictions. The reservation in respect of labour
								disputes or blockade is applicable even if the Party claiming relief itself initiates or
								is the object of such action.
							</>,
							<>
								The Party affected shall give prompt notice thereof to the other Party. However, a Party
								shall immediately notify the other Party of any such event and actively seek to overcome
								the impediment.
							</>,
						],
					},
					{
						title: "ENTIRE AGREEMENT",
						skipItemNumbers: true,
						items: [
							<>
								The contents of the Terms shall supersede all previous written or oral commitments and
								undertakings.
							</>,
						],
					},
					{
						title: "SEVERABILITY",
						skipItemNumbers: true,
						items: [
							<>
								In the event any provision of the Terms or part thereof is held to be invalid, this
								shall not mean that the Terms as a whole are invalid; instead, a reasonable adjustment
								shall be made to the Terms to the extent the invalid provision has a material effect on
								the Parties' rights and obligations and performance under the Terms.
							</>,
						],
					},
					{
						title: "WAIVER",
						skipItemNumbers: true,
						items: [
							<>
								No consent or waiver, expressed or implied, by either Party of any breach or default of
								the other Party in performing its obligations under the Terms shall be deemed or
								construed to be a consent or waiver of any other breach or default by the other Party of
								the same or any other obligation hereunder. Any failure by one Party to complain of any
								act or failure to act of the other Party or to declare that other Party in default shall
								not constitute a waiver by the first Party of its rights under the Terms. No waiver of
								any rights under the Terms shall be effective unless in writing and signed by the Party
								purporting to give the same.
							</>,
						],
					},
					{
						title: "COMPLAINTS AND DISPUTES",
						items: [
							<>
								If you file a complaint, such complaint may be sent to the Tutor by e-mail, using the
								following address: {platform.platform!.contactEmail}
							</>,
							<>
								In the event you are a Consumer, you also have the right to report or file a complaint
								to the general complaints board in the applicable country. The Swedish general
								complaints board is the National Board for Consumer Disputes (ARN):{" "}
								<InlineButton to="https://www.arn.se/">www.arn.se</InlineButton>.
							</>,
							<>
								You may, in case of a transnational dispute, avail of the online dispute resolution
								platform of the European Union, which is available here:{" "}
								<InlineButton to="https://ec.europa.eu/consumers/odr">
									ec.europa.eu/consumers/odr
								</InlineButton>
								.
							</>,
							<>
								The Tutor and you endeavour to use their reasonable efforts to negotiate and settle any
								dispute or claim that may arise in relation to these Terms.
							</>,
							<>
								These Terms and all matters in relation to the Agreement shall be construed in
								accordance with Swedish law and the Swedish courts shall have jurisdiction, however
								always subject to mandatory consumer law.
							</>,
						],
					},
				],
			},
			custom: customTerms,
		},
	}
}

const InlineButton = styled(Link).attrs({ target: "_blank", rel: "noopener noreferrer" })`
	display: inline;
`

const List = styled.ol`
	margin: 8px 0 16px 16px;
`
