import { type FC } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import Button from "~/themes/school/components/Button"
import Hero from "~/themes/school/components/Hero"
import Layout from "~/themes/school/components/Layout"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import PurchaseItem from "~/themes/school/components/PurchaseItem"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

const BundlePage: FC = () => {
	const t = useTranslation()

	const bundleId = parseNumber(useParams().bundleId)

	const { data, error, mutate } = swr.bundle.getPersonalized.useSWR(bundleId ?? -1)

	if (data === null) {
		return <Navigate to={routes.bundle.index()} replace />
	}

	return (
		<PurchaseSuccessModalContextProvider
			content={{
				type: "bundle",
				isUnlocked: data?.isPurchased ?? false,
				payPalOrderId: data?.isPurchased && data.order?.type === "paypal" ? data.order.id : null,
			}}
		>
			<Layout>
				{error ? (
					<p>{t("failedToLoad")}</p>
				) : !data ? (
					<PartialLoadingPage />
				) : (
					<Hero
						title={data.name}
						htmlDescription={data.longDescription?.html}
						callToAction={
							!data.isPurchased ? (
								<PurchaseItem
									item={{ type: "bundle", id: data.id, price: data.price }}
									onPurchase={mutate}
								/>
							) : (
								<Button variant="primary" isDisabled>
									{t("bundle.owned")}
								</Button>
							)
						}
						thumbnail={
							data.thumbnailFilePath !== null ? <Thumbnail src={data.thumbnailFilePath} /> : undefined
						}
					/>
				)}
			</Layout>
		</PurchaseSuccessModalContextProvider>
	)
}

const Thumbnail = styled.img.attrs({ alt: "Bundle thumbnail" })`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

export default BundlePage
