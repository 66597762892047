import { type ReactNode, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import styled, { css } from "styled-components"

import Button from "~/themes/original/components/Button"
import { lightTextColor } from "~/utilities/styles"

interface TabsProps {
	tabs: {
		title: string
		content: ReactNode
		icon?: ReactNode
	}[]
	selectedTabIndex: number
	setSelectedTabIndex: (value: number) => void
	className?: string
}

interface ControlledTabsProps {
	tabs: {
		title: string
		content: ReactNode
	}[]
	className?: string
}

interface RouteTabsProps {
	tabs: {
		title: string
		route: string
		content: ReactNode
	}[]
	className?: string
}

const Tabs: React.FC<TabsProps> = ({ selectedTabIndex, setSelectedTabIndex, tabs, className }) => (
	<div className={className}>
		<TabBar>
			{tabs.map(({ title }, index) => (
				<Tab key={index} isActive={selectedTabIndex === index} onClick={() => setSelectedTabIndex(index)}>
					{title}
				</Tab>
			))}
		</TabBar>
		{tabs.map(({ content }, index) => (
			<TabContent key={index} $isActive={selectedTabIndex === index}>
				{content}
			</TabContent>
		))}
	</div>
)

export const ControlledTabs: React.FC<ControlledTabsProps> = props => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0)

	return <Tabs selectedTabIndex={selectedTabIndex} setSelectedTabIndex={setSelectedTabIndex} {...props} />
}

export const RouteTabs: React.FC<RouteTabsProps> = ({ tabs, ...props }) => {
	const location = useLocation()
	const navigate = useNavigate()

	const routeIndex = tabs.findIndex(x => x.route === location.pathname)

	const setSelectedTabIndex = (index: number) => {
		navigate(tabs[index].route)
	}

	return (
		<Tabs
			tabs={tabs}
			selectedTabIndex={routeIndex >= 0 ? routeIndex : 0}
			setSelectedTabIndex={setSelectedTabIndex}
			{...props}
		/>
	)
}

const TabBar = styled.div`
	width: 100%;
	display: flex;
	gap: 22px;
	overflow-x: auto;
`

const Tab = styled(Button)<{ isActive: boolean }>`
	font-weight: ${props => (props.isActive ? 600 : 400)};
	font-size: 16px;
	color: ${props => (props.isActive ? "white" : lightTextColor)};
	background-color: ${props => (props.isActive ? props.theme.primaryColor : "white")};
	border: 1px solid ${props => (props.isActive ? props.theme.primaryColor : "#E8E8ED")};
	border-radius: 8px;
	padding: 11px 23px;
	transition:
		0.15s background-color,
		0.15s color,
		0.15s border-color;

	${props =>
		!props.isActive &&
		css`
			letter-spacing: 0.3px;
		`}
`

const TabContent = styled.div<{ $isActive: boolean }>`
	${props =>
		!props.$isActive &&
		css`
			display: none;
		`}
`

export default Tabs
