import { Navigate, useSearchParams } from "react-router"
import styled from "styled-components"

import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

const CreateProfilePage: React.FC = () => {
	const [searchParams] = useSearchParams()
	const user = useUser()

	if (user.user!.communityProfileId !== null) {
		return <Navigate to={searchParams.get("next") ?? routes.community.index()} />
	}

	const handleCreateProfile = async () => {
		await trpc.community.createProfile.mutate()
		await user.reload()
	}

	return (
		<Layout>
			<Title>Join the community</Title>
			<Text>In order to access the community you need to make your profile visible to the community.</Text>
			<StyledButton onClick={handleCreateProfile}>Make profile visible</StyledButton>
		</Layout>
	)
}

const Title = styled.h1`
	font-weight: 500;
	font-size: 36px;
	color: #151d48;
	margin-bottom: 24px;
`

const Text = styled.p`
	font-size: 18px;
	color: ${lightTextColor};
	margin-bottom: 16px;
`

const StyledButton = styled(Button).attrs({ variant: "primary" })`
	align-self: flex-start;
`

export default CreateProfilePage
