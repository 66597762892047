const routes = {
	app: {
		main: {
			index: () => "/",
			analytics: () => "/analytics",
			discountCodes: () => "/discount-codes",
			event: () => "/events",
			websiteEditor: () => "/website",
			referral: () => "/referrals",
		},
		account: {
			signin: () => "/account/signin",
			signup: () => "/account/signup",
			appSumoSignup: (appSumoKey: string | null) => `/account/signup/appsumo/${appSumoKey ?? ":appSumoKey"}`,
			vieDeDingueSignup: () => "/account/signup/viededingue",
			createPlatform: () => "/account/create-platform",
			selectFindSource: () => "/account/how-did-you-hear-about-us",
			selectIntent: () => "/account/select-intent",
			setContactEmail: () => "/account/contact-email",
			startPlanSubscriptionPage: () => "/account/start-subscription",
			startAddonSubscriptionPage: () => "/account/start-addon-subscription",
			verify: () => "/account/verify",
			selectPlan: () => "/account/select-plan",
			planStarted: () => "/account/plan-started",
			resetPassword: () => "/account/reset-password",
			newPassword: () => "/account/new-password",
		},
		bundle: {
			index: () => "/bundles",
			detail: (bundleId: number | null) => `/bundles/${bundleId ?? ":bundleId"}`,
		},
		community: {
			index: () => "/community",
			profiles: () => "/community/profiles",
			profile: (profileId: number | null) => `/community/profiles/${profileId ?? ":profileId"}`,
			posts: () => `/community/posts`,
			postCategories: () => `/community/post-categories`,
			leaderboard: () => "/community/leaderboard",
			messages: () => `/community/messages`,
			message: (otherProfileId: number | null) => `/community/messages/${otherProfileId ?? ":otherProfileId"}`,
			settings: () => "/community/settings",
		},
		course: {
			index: () => "/courses",
			detail: (courseId: number | null) => `/courses/${courseId ?? ":courseId"}`,
			inputs: (courseId: number | null) => `/courses/${courseId ?? ":courseId"}/inputs`,
			reviewComments: (courseId: number | null) => `/courses/${courseId ?? ":courseId"}/review-comments`,
			settings: (courseId: number | null) => `/courses/${courseId ?? ":courseId"}/settings`,
		},
		exam: {
			index: () => "/exams",
			detail: (examId: number | null) => `/exams/${examId ?? ":examId"}`,
			completions: (examId: number | null) => `/exams/${examId ?? ":examId"}/completions`,
		},
		membership: {
			index: () => "/membership",
		},
		payments: {
			index: () => "/payments",
			completingStripe: () => "/payments/completing/stripe",
			completingPayPal: () => "/payments/completing/paypal",
		},
		downloadable: {
			index: () => "/downloadables",
			create: () => `/downloadables/create`,
			edit: (downloadableId: number | null) => `/downloadables/${downloadableId ?? ":downloadableId"}/edit`,
		},
		sessions: {
			index: () => "/sessions",
		},
		settings: {
			index: () => "/settings",
			account: () => "/settings/account",
			domain: {
				index: () => "/settings/domain",
				register: () => "/settings/domain/register",
				manageDns: (domainPurchaseId: number | null) =>
					`/settings/domain/${domainPurchaseId ?? ":domainPurchaseId"}/dns`,
			},
			billing: {
				plans: () => "/settings/billing/plans",
				history: () => "/settings/billing/history",
				paymentMethod: () => "/settings/billing/payment-method",
			},
			notification: () => "/settings/notifications",
			platform: () => "/settings/platform",
			studentEmail: {
				index: () => "/settings/student-emails",
				layout: () => "/settings/student-emails/layout",
				detail: (emailId: string | null) => `/settings/student-emails/${emailId ?? ":emailId"}`,
			},
			team: () => "/settings/team",
			zapier: () => "/settings/zapier",
		},
		user: {
			index: () => "/users",
			detail: (userId: number | null) => `/users/${userId ?? ":userId"}`,
		},
		video: {
			index: () => "/videos",
			create: () => "/videos/create",
			upload: (videoId: number | null) => `/videos/${videoId ?? ":videoId"}/upload`,
			edit: (videoId: number | null) => `/videos/${videoId ?? ":videoId"}`,
		},
	},
	platform: {
		billing: {
			index: () => "/billing",
		},
		bundle: {
			index: () => "/bundles",
			detail: (bundleId: number | null) => `/bundles/${bundleId ?? ":bundleId"}`,
		},
		certificate: {
			index: () => "/certificates",
			detail: (id: number | null) => `/certificates/${id ?? ":certificateId"}`,
		},
		event: {
			index: () => "/events",
			detail: (eventId: number | null) => `/events/${eventId ?? ":eventId"}`,
		},
		community: {
			index: () => "/community",
			create: () => "/community/create",
			conversations: () => "/community/messages",
			conversation: (otherProfileId: number | null) =>
				`/community/messages/${otherProfileId ?? ":otherProfileId"}`,
			post: (postId: number | null) => `/community/post/${postId ?? ":postId"}`,
			profile: (profileId: number | null) => `/community/profile/${profileId ?? ":profileId"}`,
			leaderboard: () => "/community/leaderboard",
		},
		course: {
			index: () => `/courses`,
			detail: (courseId: number | null) => `/courses/${courseId ?? ":courseId"}`,
			player: (courseId: number | null) => `/courses/${courseId ?? ":courseId"}/player`,
		},
		exam: {
			index: () => "/exams",
			detail: (examId: number | null) => `/exams/${examId ?? ":examId"}`,
			start: (examId: number | null) => `/exams/${examId ?? ":examId"}/start`,
			result: (resultId: number | null) => `/exams/result/${resultId ?? ":resultId"}`,
		},
		membership: {
			index: () => "/memberships",
			detail: (membershipId: number | null) => `/memberships/${membershipId ?? ":membershipId"}`,
			completingPayPal: () => "/membership/completing/paypal",
		},
		policy: {
			termsOfService: () => "/policy/terms-of-service",
			privacy: () => "/policy/privacy",
		},
		session: {
			index: () => "/sessions",
			detail: (sessionId: number | null) => `/sessions/${sessionId ?? ":sessionId"}`,
		},
		downloadable: {
			index: () => "/downloadables",
			detail: (downloadableId: number | null) => `/downloadables/${downloadableId ?? ":downloadableId"}`,
		},
		user: {
			signin: () => "/user/signin",
			signup: () => "/user/signup",
			invitedSignupMembership: () => "/user/signup/membership",
			resetPassword: () => "/user/password/reset",
			newPassword: () => "/user/password/new",
			completeProfile: () => "/user/complete-profile",
			settings: () => "/user/settings",
		},
		video: {
			index: () => "/videos",
			detail: (videoId: number | null) => `/videos/${videoId ?? ":videoId"}`,
		},
	},
}

export default routes
