import { useState, type FC } from "react"
import { Trans } from "react-i18next"
import { Navigate, useParams } from "react-router"
import styled, { css } from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"
import Tooltip from "@forento/shared/components/Tooltip"
import VideoPlayer from "@forento/shared/components/VideoPlayer"
import { type DetailedCoursePage } from "@forento/shared/models/course"
import { toDateTimeString, toRelativeDateString } from "@forento/shared/utilities/date"
import { parseNumber } from "@forento/shared/utilities/number"

import { useCoursePlayer } from "~/api/course"
import { usePlatform } from "~/contexts/PlatformContext"
import Button from "~/themes/school/components/Button"
import CourseComments from "~/themes/school/components/CourseComments"
import FullLoadingPage from "~/themes/school/components/FullLoadingPage"
import { ArrowIcon, SkipIcon } from "~/themes/school/components/Icon"
import Layout from "~/themes/school/components/Layout"
import { PageBreadcrumb } from "~/themes/school/components/PageHeader"
import Resources from "~/themes/school/components/Resources"
import { ControlledTabs } from "~/themes/school/components/Tabs"
import { fonts } from "~/themes/school/styles"
import { useLocale, useTranslation } from "~/translations"
import routes from "~/utilities/routes"

import Attachments from "./Attachments"
import CertificateImage from "./CertificateImage"
import ChaptersList from "./ChaptersList"
import InputAnswerType from "./answers/InputAnswerType"
import MultiAnswerType from "./answers/MultiAnswerType"
import SingleAnswerType from "./answers/SingleAnswerType"
import background from "./background.svg"

const CoursePlayerPage: FC = () => {
	const courseId = parseNumber(useParams().courseId!)
	const platform = usePlatform().platform
	const t = useTranslation()
	const locale = useLocale()

	const player = useCoursePlayer(courseId ?? -1)

	const [isCertificateShown, setCertificateShown] = useState(false)

	if (player.status === "not-found") return <Navigate to={routes.course.index()} />

	return (
		<Layout>
			<PageBreadcrumb
				path={[{ title: t("course.title"), link: routes.course.index() }]}
				title={player.course?.title ?? "..."}
			/>
			{player.status === "error" ? (
				t("failedToLoad")
			) : player.status === "loading" ? (
				<FullLoadingPage />
			) : (
				<>
					{player.current === null ? (
						platform.content.certificates && player.course.issueCertificates && !isCertificateShown ? (
							<PageContent>
								<CertificateImage />
								<CourseCompletedTitle>{t("certificate.received")}</CourseCompletedTitle>
								<Buttons>
									<StyledButton variant="secondary" onClick={player.previousPage}>
										{t("course.previous")}
									</StyledButton>
									<StyledButton variant="secondary" onClick={routes.certificate.index()}>
										{t("certificate.goToAll")}
									</StyledButton>
									<StyledButton variant="primary" onClick={() => setCertificateShown(true)}>
										{t("course.continue")}
									</StyledButton>
								</Buttons>
							</PageContent>
						) : (
							<PageContent>
								<CourseCompletedTitle>{t("course.completed")}</CourseCompletedTitle>
								<Buttons>
									<StyledButton variant="secondary" onClick={player.previousPage}>
										{t("course.previous")}
									</StyledButton>
									<StyledButton variant="primary" onClick={routes.course.index()}>
										{t("course.goToAll")}
									</StyledButton>
								</Buttons>
							</PageContent>
						)
					) : player.current.status === "locked" ? (
						<PageContent>
							<CourseCompletedTitle>{t("course.pageLocked")}</CourseCompletedTitle>
							<Buttons>
								<StyledButton variant="secondary" onClick={player.previousPage}>
									{t("course.previous")}
								</StyledButton>
								<StyledButton variant="primary" onClick={routes.course.index()}>
									{t("course.goToAll")}
								</StyledButton>
							</Buttons>
						</PageContent>
					) : player.current.status === "not-dripped" ? (
						<PageContent>
							<PageTitle>{t("course.chapterNotDripped")}</PageTitle>
							<PageText>
								<Trans
									i18nKey="course.chapterNotDrippedText"
									values={{ relativeDate: toRelativeDateString(player.current.dripDate, locale) }}
									components={{
										tooltip: (
											<DripDateTooltip
												inline
												tooltip={toDateTimeString(player.current.dripDate)}
											/>
										),
									}}
								/>
							</PageText>
							<Buttons>
								<StyledButton variant="secondary" onClick={player.previousPage}>
									{t("course.previous")}
								</StyledButton>
								<StyledButton variant="primary" onClick={routes.course.index()}>
									{t("course.goToAll")}
								</StyledButton>
							</Buttons>
						</PageContent>
					) : player.current.page.youTubeVideoId ? (
						<PageContent>
							<VideoContainer>
								<AspectRatio width={{ unit: "percent", value: 100 }} aspectRatio={16 / 9}>
									<iframe
										width="100%"
										height="100%"
										style={{ border: "none" }}
										src={`https://www.youtube-nocookie.com/embed/${player.current.page.youTubeVideoId}`}
										title="YouTube video player"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										referrerPolicy="strict-origin-when-cross-origin"
										allowFullScreen
									/>
								</AspectRatio>
							</VideoContainer>
							<PageTitle>{player.current.page.title}</PageTitle>
							{player.current.page.htmlContent !== null && (
								<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
							)}
							<Attachments
								links={player.current.page.links}
								attachments={player.current.page.attachments}
							/>
							<Buttons>
								<PreviousButton variant="secondary" onClick={player.previousPage}>
									{t("course.previous")}
								</PreviousButton>
								<NextButton variant="primary" onClick={player.nextPage}>
									{t("course.continue")}
								</NextButton>
								<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
									{t("course.skipChapter")}
								</SkipChapterButton>
							</Buttons>
						</PageContent>
					) : player.current.page.vimeoVideoId ? (
						<PageContent>
							<VideoContainer>
								<AspectRatio width={{ unit: "percent", value: 100 }} aspectRatio={16 / 9}>
									<iframe
										width="100%"
										height="100%"
										style={{ border: "none" }}
										src={`https://player.vimeo.com/video/${player.current.page.vimeoVideoId}?h=fa26a9e8f5&loop=1&title=0&byline=0&portrait=0&badge=0`}
										allow="autoplay; fullscreen; picture-in-picture"
										allowFullScreen
									/>
								</AspectRatio>
							</VideoContainer>
							<PageTitle>{player.current.page.title}</PageTitle>
							{player.current.page.htmlContent !== null && (
								<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
							)}
							<Attachments
								links={player.current.page.links}
								attachments={player.current.page.attachments}
							/>
							<Buttons>
								<PreviousButton variant="secondary" onClick={player.previousPage}>
									{t("course.previous")}
								</PreviousButton>
								<NextButton variant="primary" onClick={player.nextPage}>
									{t("course.continue")}
								</NextButton>
								<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
									{t("course.skipChapter")}
								</SkipChapterButton>
							</Buttons>
						</PageContent>
					) : player.current.page.video?.isCompleted ? (
						<PageContent>
							<VideoContainer>
								<VideoPlayer video={player.current.page.video} />
							</VideoContainer>
							<PageTitle>{player.current.page.title}</PageTitle>
							{player.current.page.htmlContent !== null && (
								<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
							)}
							<Attachments
								links={player.current.page.links}
								attachments={player.current.page.attachments}
							/>
							<Buttons>
								<PreviousButton variant="secondary" onClick={player.previousPage}>
									{t("course.previous")}
								</PreviousButton>
								<NextButton variant="primary" onClick={player.nextPage}>
									{t("course.continue")}
								</NextButton>
								<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
									{t("course.skipChapter")}
								</SkipChapterButton>
							</Buttons>
						</PageContent>
					) : (
						<PageContent>
							{player.current.page.image !== null && (
								<PageImage
									src={player.current.page.image.filePath}
									fit={player.current.page.image.contain ? "contain" : "cover"}
								/>
							)}
							<PageTitle>{player.current.page.title}</PageTitle>
							{player.current.page.htmlContent !== null && (
								<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
							)}
							<Attachments
								links={player.current.page.links}
								attachments={player.current.page.attachments}
							/>
							<Answers>
								{getAnswers({ page: player.current.page, setSelection: player.setSelection })}
							</Answers>
							<Buttons>
								<PreviousButton variant="secondary" onClick={player.previousPage}>
									<ButtonIcon as={ArrowIcon} direction="left" />
									{t("course.previous")}
								</PreviousButton>
								<NextButton
									variant="primary"
									onClick={player.nextPage}
									isDisabled={!player.isNextPageEnabled}
								>
									<ButtonIcon as={ArrowIcon} />
									{t("course.continue")}
								</NextButton>
								<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
									<ButtonIcon as={SkipIcon} />
									{t("course.skipChapter")}
								</SkipChapterButton>
							</Buttons>
						</PageContent>
					)}
					<Details>
						<ControlledTabs
							tabs={[
								{
									title: t("course.chapters"),
									content: (
										<ChaptersList
											courseId={player.course.id}
											currentPageId={
												player.current?.status === "unlocked" ? player.current.page.id : null
											}
											firstUncompletedPageId={player.course.firstUncompletedPage?.id ?? null}
											setPage={player.setPageById}
										/>
									),
								},
								{
									title: t("course.comments"),
									content: (
										<CourseComments
											courseId={player.course.id}
											pageId={
												player.current?.status === "unlocked" ? player.current.page.id : null
											}
											commentsUnderReview={player.course.commentsUnderReview}
											reloadCourse={player.reloadCourse}
										/>
									),
								},
								...(player.course.resources !== undefined && player.course.resources.length > 0
									? [
											{
												title: t("course.resources"),
												content: <Resources resources={player.course.resources} />,
											},
										]
									: []),
							]}
						/>
					</Details>
				</>
			)}
		</Layout>
	)
}

const getAnswers = ({ page, setSelection }: { page: DetailedCoursePage; setSelection(selection: unknown): void }) => {
	if (page.inputType !== null)
		return <InputAnswerType key={page.id} inputType={page.inputType} onChange={setSelection} />
	if (page.quiz === null) return null
	if (page.quiz.type === "single-answer")
		return <SingleAnswerType answers={page.quiz.answers} setSelection={setSelection} />
	else return <MultiAnswerType answers={page.quiz.answers} setSelection={setSelection} />
}

const PageContent = styled.div`
	background-color: white;
	background-image: url(${background});
	background-size: cover;
	background-position: center center;
	padding: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 24px;

	@media (min-width: 1000px) {
		min-height: calc(100vh - ${42 + 28 + 32 + 42}px);
		padding: 42px;
		margin-bottom: 42px;
	}
`

const VideoContainer = styled.div`
	width: 100%;
	max-width: 800px;
	margin-bottom: 24px;
`

const PageImage = styled.img.attrs({ alt: "Page illustration" })<{ fit: "cover" | "contain" }>`
	width: 100%;
	max-width: 500px;
	border-radius: 8px;
	margin-bottom: 24px;

	${props =>
		props.fit === "cover" &&
		css`
			aspect-ratio: 3;
			object-fit: cover;
		`}
`

const PageTitle = styled.h2`
	font-family: ${fonts.quicksand};
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 24px;
`

const CourseCompletedTitle = styled(PageTitle)`
	margin-bottom: 24px;
`

const PageHtmlText = styled.div`
	max-width: 500px;
	font-size: 14px;
	text-align: center;
	margin-bottom: 24px;
	display: flex;
	flex-direction: column;
	gap: 14px;

	* {
		text-align: center !important;
	}
`

const PageText = styled.div`
	max-width: 500px;
	font-size: 14px;
	text-align: center;
	margin-bottom: 24px;
`

const DripDateTooltip = styled(Tooltip)`
	text-decoration: underline dotted;
`

const Answers = styled.div`
	width: 100%;
	max-width: 500px;

	&:not(:empty) {
		margin-bottom: 32px;
	}
`

const Buttons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	@media (min-width: 1000px) {
		display: grid;
		grid-template-areas: "previous next skipChapter";
		gap: 16px;
	}
`

const StyledButton = styled(Button)`
	min-width: 150px;

	@media (max-width: 999px) {
		min-width: 0;
		flex: 1;
	}
`

const ButtonIcon = styled.div`
	width: 14px;
	height: 14px;
`

const PreviousButton = styled(StyledButton).attrs({ variant: "secondary" })`
	grid-area: previous;
`

const NextButton = styled(StyledButton).attrs({ variant: "primary" })`
	grid-area: next;
`

const SkipChapterButton = styled(StyledButton).attrs({ variant: "secondary" })`
	grid-area: skipChapter;
`

const Details = styled.div`
	background-color: white;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 32px;

	@media (min-width: 1000px) {
		padding: 48px 86px;
	}
`

export default CoursePlayerPage
