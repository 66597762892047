import { Link } from "react-router"
import styled from "styled-components"

import { usePlatform } from "~/contexts/PlatformContext"

export function usePrivacyPolicy() {
	const platform = usePlatform()

	return {
		introItems: [
			<>
				The course provider ("Tutor" or "we"/"us") process personal data in accordance with the Regulation (EU)
				2016/679 of the European Parliament and of the Council of 27 April 2016, General Data Protection
				Regulation ("GDPR"), and any other Swedish laws and regulations applicable in the field of data
				protection. This Privacy Policy explains how we use the personal data that we collect from you when you
				use our Service as stated in our TERMS FOR THE PURCHASE OF ON-LINE COURSES. It also describes your
				rights toward us and how you can exercise your rights.
			</>,
			<>
				This Privacy Policy supplements the TERMS FOR THE PURCHASE OF ON-LINE COURSES whenever any of Tutor's
				customers ("data subjects") purchases on-line courses ("Services") and thereby provides Tutor with
				personal data that is or will be subject to data protection laws. Tutor shall be referred to as data
				controller.
			</>,
			<>
				Tutor will only process personal data to the extent necessary to fulfill the Services under the TERMS
				FOR THE PURCHASE OF ON-LINE COURSES and for purposes which are compatible with providing the Services.
				In this regard, Tutor is the controller of your personal data.
			</>,
		],
		sections: [
			{
				title: "TUTOR'S PURPOSES AND LEGAL GROUNDS FOR PROCESSING YOUR PERSONAL DATA",
				items: [
					<>
						"Personal data" means any information relating to an identified or identifiable natural person
						that, directly or indirectly in combination with other information, can be linked to a living,
						natural person. Personal data is a very broad term, and it includes the name, contact details
						and IP addresses of such person.
					</>,
					<>
						"Processing" of personal data means, including but not limited to, collecting, registering,
						organizing, structuring, and storing. Processing also means alteration, production, reading,
						listing, using, and disclosing personal data by transfer, disseminating, changing, removing as
						well as deletion of the personal data.
					</>,
					<>"Data subject" means an identified or identifiable person to whom personal data relates.</>,
					<>
						Tutor will only process personal data if we have a lawful basis for doing so. Lawful bases for
						processing include consent, contractual necessity and our "legitimate interests" or the
						legitimate interest of others. Tutor may process personal data based on the following legal
						grounds in GDPR:
					</>,
					<List>
						<li>
							In the event Tutor foresees the need to process personal data for a purpose which, pursuant
							to applicable laws and regulations, should be based on consent (on grounds of Art. 6(1)(a)
							GDPR), Tutor will contact you in advance, to inform you about the processing for which your
							consent is required before the consent is collected and ask for your consent.
						</li>
						<li>
							To fulfil an agreement with you or to respond to your request for customer service. The
							legal ground for this is that the processing is necessary for the performance of an
							agreement or contract to which the data subject is party or to take steps at the request of
							the data subject prior to entering into an agreement or contract (Art. 6(1)(b) GDPR).
						</li>
						<li>
							To comply with legal obligations to report to authorities and third parties. The legal
							ground is that processing is necessary for compliance with a legal obligation to which the
							data controller is subject (Art. 6(1)(c) GDPR).
						</li>
						<li>
							To satisfy a legitimate and justified interest of Tutor in processing your personal data,
							such as marketing our products and services by contacting potential and existing customers
							via e-mail, newsletters, or letters, in order to inform them about Tutor's services, offers,
							events and products. The legal ground for this is that the processing is necessary and that
							Tutor has a legitimate interest (Art. 6(1)(f) GDPR) that is not overridden by the
							fundamental interests and freedoms of the data subjects.
						</li>
					</List>,
				],
			},
			{
				title: "DIFFERENT CATEGORIES OF THE DATA SUBJECT'S PERSONAL DATA",
				items: [
					<>
						When you visit one of our digital channels (for example our website, social media and video
						sharing sites, our mobile applications, online events, digital meetings, webinars, online
						training etc.), as well as when you contact us via e-mail or our website's chat function, we may
						collect information about you, such as your name, address, postal address, e-mail address, phone
						number, identification data and information regarding your use of Tutor's products and services.
						In some cases, we may process your personal identity number. Tutor may also collect your
						personal data from other public sources and external partners from other countries. Personal
						data that may be collected and processed by Tutor could include:
					</>,
					<List>
						<li>
							your name, e-mail address, postal address, name and contact details of your employer,
							telephone number, banking information and payment details, IP address, picture, personal
							settings;
						</li>
						<li>
							cookie files stored on your computer or your telephone (or other digital device which you
							may use to visit our digital channels) for the purpose of identifying your browser and to
							recognize your settings and preferences. You will have the right to refuse Tutor's
							processing of personal data using cookie files; and
						</li>
						<li>
							other information regarding your use of Tutor's products and services, which we may collect
							from our surveys and other means of communication with Tutor.
						</li>
					</List>,
					<>
						Please note that when you enter your personal data in connection with using the services of our
						payment providers, you must read and accept the payment provider's terms and conditions, which
						can be found here:{" "}
						<InlineButton to="https://stripe.com/privacy">stripe.com/privacy</InlineButton>.
					</>,
				],
			},
			{
				title: "PROCESSING AND TRANSFER OF YOUR PERSONAL DATA",
				items: [
					<>
						The Data subject has through this Privacy Policy been informed about the fact that personal data
						will be stored in a database for the purposes described above. If Tutor engages co-operation
						partners, Tutor shall ensure that any personal data is afforded equivalent protection as
						prescribed in this Privacy Policy and in accordance with the GDPR. Tutor may also transfer
						personal data to others within Tutor's business operation, coordinators, co-operation partners
						and providers, as well as third parties, including but not limited to suppliers, cloud service
						providers, consultants, and authorities. Tutor shall, however, only transfer personal data if
						Tutor has a legal ground under the GDPR to do so.
					</>,
					<>
						Tutor may also transfer personal data to a third country, i.e. a country outside the EU/EEA, or
						to international organizations according to applicable laws and data regulations. Tutor and
						third parties may be based anywhere in the world, which could include countries that may not
						offer the same legal protections for personal data as the EU/EEA. Tutor will comply with local
						data protection requirements and its internal global privacy standards and Tutor will apply the
						necessary safeguards under the applicable law of the country transferring the data for such
						transfers.
					</>,
					<>
						The personal data will be, dependent on the purpose for which it is collected, archived,
						confidentially erased, or anonymized in accordance with the rules of archiving when it is no
						longer necessary. Personal data will be stored during the time it is necessary for Tutor to
						fulfil its obligations and for the purposes set out above. Tutor will bring necessary measures
						to provide the personal data with protection against unauthorized access and loss thereof.
					</>,
					<>
						In the event a personal data incident incurs, Tutor shall notify the Data subject as soon as
						possible and in any event within 72 hours of becoming aware of the incident. A personal data
						incident means a security incident that leads to an accidental or unlawful destruction, loss or
						alteration, unauthorized disclosure or unauthorized access to the personal data transferred,
						stored, or otherwise processed. As personal data will be transferred over the internet, it is
						important for data subjects to be aware of the associated risks.
					</>,
					<>
						If the personal data incident leads to a high risk to the rights and freedom of a data subject,
						such data subjects must also be contacted without delay.
					</>,
					<>
						Subject to applicable data protection legislation, Tutor shall not be liable for any damages
						arising from the processing of personal data. In any event, Tutor shall not be liable for
						indirect damages. Notwithstanding anything said in this clause 3.6, Tutor is not seeking to
						exclude liability for gross negligence and wilful misconduct, nor any other mandatory terms and
						conditions stipulated by Swedish and European law.
					</>,
				],
			},
			{
				title: "THE DATA SUBJECT'S LEGAL RIGHTS",
				items: [
					<>
						Data subjects have the right to object to Tutor's processing of the personal data such as, for
						example, when processed in connection with direct marketing. Data subjects also have the right
						to request deletion, restriction, and rectification of the personal data. If consent is
						withdrawn, or if the stored personal data is incorrect or irrelevant, Tutor must delete,
						restrict, or correct such personal data.
					</>,
					<>
						Data subjects have the right to request information about Tutor's processing of personal data.
						If a request is made electronically, Tutor shall provide the information in an electronically
						readable form which is structured and commonly used. Any request from data subjects shall be
						answered within a reasonable period by Tutor.
					</>,
					<>
						Tutor shall, upon request, provide information about the purpose of the processing, what
						personal data is being processed, recipients of the personal data and, if possible, for how long
						the personal data will be stored. Upon request, Tutor shall also provide information about the
						possibility to request deletion, rectification, or alteration of the personal data, as well as
						how to lodge a complaint to Tutor or the competent supervisory authority. Furthermore, Tutor
						shall upon request provide information about the origin of the personal data, the existence of
						profiling and automatic decision-making, and any transfers to third countries. If requested,
						Tutor shall also provide the data subjects with a copy of the processed personal data.
					</>,
					<>
						Data subjects have the right to transfer the personal data to another data controller (data
						portability), as well as to lodge a complaint regarding Tutor's processing of personal data.
						Complaints shall be submitted to Tutor and/or the competent supervisory authority according to
						the contact details in clause 5 below.
					</>,
					<>
						This Privacy Policy may be updated at any time and the latest updated version may always be
						found on Tutor's website:{" "}
						<InlineButton to={`https://${platform.platform!.domain.platform}`}>
							{platform.platform!.domain.platform}
						</InlineButton>
					</>,
				],
			},
			{
				title: "CONTACT INFORMATION",
				items: [
					<>
						Tutor's email address for contact is:{" "}
						<InlineButton to={`mailto:${platform.platform!.contactEmail}`}>
							{platform.platform!.contactEmail}
						</InlineButton>
						.
					</>,
					<>
						The contact details of the competent supervisory authority in Sweden are as follows: The Swedish
						Authority for Privacy Protection (Sw. "Integritetsskyddsmyndigheten"), org.no. 202100-0050, with
						address Box 8114, SE-104 20 Stockholm, Sweden. The Swedish Authority for Privacy Protection can
						also be contacted by telephone,{" "}
						<InlineButton to="tel:+4686576100">+46 (0)8-657 61 00</InlineButton>, or by e-mail,{" "}
						<InlineButton to="mailto:imy@imy.se">imy@imy.se</InlineButton>. For more information about the
						Swedish Authority for Privacy Protection, please visit:{" "}
						<InlineButton to="https://www.imy.se">www.imy.se</InlineButton>.
					</>,
				],
			},
		],
	}
}

const InlineButton = styled(Link).attrs({ target: "_blank", rel: "noopener noreferrer" })`
	display: inline;
`

const List = styled.ul`
	margin: 0 0 16px 16px;
`
