import { type FC } from "react"
import { Link } from "react-router"

import Tooltip from "@forento/shared/components/Tooltip"
import { toDateTimeString, toRelativeDateString } from "@forento/shared/utilities/date"

import Card, { CardHeader, CardSticker, CardTitle, Cards } from "~/themes/school/components/Card"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { useLocale, useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

const EventsPage: FC = () => {
	const t = useTranslation()
	const locale = useLocale()

	const { data: upcomingEvents, error } = swr.event.listPublic.useSWR()

	return (
		<Layout>
			<PageTitle>{t("event.title")}</PageTitle>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : upcomingEvents === undefined ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{upcomingEvents.map(event => (
						<Card key={event.id} as={Link} to={routes.event.detail(event.id)}>
							<CardHeader>
								<CardTitle>{event.title}</CardTitle>
								<Tooltip tooltip={toDateTimeString(event.startDate)}>
									<CardSticker>{toRelativeDateString(event.startDate, locale)}</CardSticker>
								</Tooltip>
							</CardHeader>
						</Card>
					))}
				</Cards>
			)}
		</Layout>
	)
}

export default EventsPage
